const breakpoints = {
    xs: '(min-width: 320px)',
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    dt: '(min-width: 1035px)',
    lg: '(min-width: 1400px)',
    xl: '(min-width: 1775px)',
};

export const isMatching = (bp) => {
    if (!window.matchMedia) {
        console.error('window.matchMedia() is not available');
        return false;
    }

    return window.matchMedia(breakpoints[bp]).matches;
};
