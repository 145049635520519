import {Controller} from '@hotwired/stimulus';
import {useTransition} from 'stimulus-use';

export default class extends Controller {
    static targets = ['modal'];

    connect() {
        useTransition(this, {
            element: this.modalTarget,
            // enterActive: 'transition ease-out duration-300',
            // enterFrom: 'transform opacity-0',
            // enterTo: 'transform opacity-100',
            leaveActive: 'transition ease-in duration-100',
            leaveFrom: 'transform opacity-100 scale-100',
            leaveTo: 'transform opacity-0',
        });
    }

    disconnect() {
        this.closeModal();
    }

    escapeModal(e) {
        if (e.code && e.code === 'Escape') {
            this.closeModal();
        }
    }

    reload() {
        this.modalFrame.reload();
    }

    open() {
        if (typeof this.enter === 'function') {
            this.enter();
        } else if (this.hasModalTarget) {
            this.modalTarget.classList.remove('hidden');
        }
        this.dispatch('modalOpen', {detail: {modal: this.modalTarget}});
    }

    closeModal() {
        this.dispatch('modalClose', {detail: {modal: this.modalTarget}});
        if (typeof this.leave === 'function') {
            this.leave().then(() => {
                this.resetModal();
            });
        } else if (this.hasModalTarget) {
            this.modalTarget.classList.add('hidden');
            this.resetModal();
        }
    }

    openModal(e) {
        let url = typeof e.params.url !== 'undefined' ? e.params.url : e.currentTarget.dataset.url;
        if (url) {
            this.modalFrame.src = url;
            this.open();
        }
    }

    shareModal(e) {
        let path = typeof e.params.path !== 'undefined' ? e.params.path : e.currentTarget.dataset.path;
        let title = typeof e.params.title !== 'undefined' ? e.params.title : e.currentTarget.dataset.title;
        if (!path || !title) {
            return;
        }

        if (navigator.share) {
            this.navigatorShare({
                title: title,
                text: title,
                url: path,
            });

            return;
        }

        this.modalFrame.src = `/share?url=${encodeURIComponent(path)}&title=${title}`;
        this.open();
    }

    toggle() {
        this.toggleTransition();
    }

    resetModal() {
        this.modalFrame.src = '';
        this.modalFrame.innerHTML = `<div class="h-[100vh] w-full flex items-center justify-center flex-row text-default">
            <svg class="animate-spin mr-3 h-5 w-5 fill-transparent" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="text-md text-default/80">Loading...</span>
        </div>`;
        this.modalFrame.removeAttribute('complete');
    }

    async navigatorShare(shareData) {
        await navigator.share(shareData);
    }

    get modalFrame() {
        return this.modalTarget.querySelector('turbo-frame');
    }
}
